














































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {
    Coupon,
    Course,
    CourseSeries,
    ProofFactorHook,
    ProofFactorHookEditable
} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Proof Factor'
        }
    }
})
export default class EditHookDialog extends Vue {
    @Prop({
        default: () => {
            return false
        }
    })
    visible!: boolean

    @Prop({
        default: () => {
            return false
        }
    })
    create: boolean

    @Prop({
        default: () => {
            return new Coupon()
        }
    })
    hook: ProofFactorHook

    org: string
    codeUsed = false

    eHook: ProofFactorHook = new ProofFactorHook()

    // generate
    length: number = 5

    selectedBlog = ''
    selectedCourse = ''

    courses: Course[] = []
    series: CourseSeries[] = []
    async created() {
        this.hookUpdated()

        const coursesRes = await createRequest('/courses/admin/select', 'get', {
            skip: 0 + '',
            limit: 0 + '',
            status: 'published,pending,preordering,private,hidden',
            offline: 0 + ''
        }).send()
        this.courses = coursesRes.data.courses

        const res = await createRequest('/courses/series', 'get', {limit: 0}).send()
        this.series = res.data.series
    }

    @Watch('hook', {deep: true})
    hookUpdated() {
        this.org = JSON.stringify(this.hook)
        this.eHook = JSON.parse(this.org)
    }
    async submit() {
        const body: ProofFactorHookEditable = ProofFactorHookEditable.from(this.eHook)

        if (this.create) {
            await createRequest('/proof-factor/hook', 'post', {}, body).send()
        } else {
            for (const key of Object.keys(body)) {
                if (body[key] === this.hook[key])
                    delete body[key]
            }
            await createRequest(`/proof-factor/hook/${this.hook._id}`, 'patch', {}, body).send()
        }

        this.$emit('update')
        this.dialogVisible = false
    }

    get changed() {
        return !(JSON.stringify(this.eHook) === this.org)
    }

    get canSubmit() {
        if (!this.create && !this.changed)
            return false
        return true
    }

    async handleClose(done) {
        if (this.changed) {
            try {
                await this.$confirm('Changes not saved, Exit?')
                done()
            } catch {

            }
        } else {
            done()
        }
    }

    set dialogVisible(v) {
        this.$emit('update:visible', v)
    }

    get dialogVisible() {
        return this.visible
    }
}
