













































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {ProofFactorHook} from '~/components/data-class/data-class'
import {ProofFactorPermission} from '~/utils/permissions'
import EditHookDialog from './proof-factor-dialog.vue'
import {checkPermissions} from '~/utils/check-permission'

@Component({
    components: {
        EditHookDialog
    }
})
export default class ProofFactorHooksManager extends Vue {
    static permission = ProofFactorPermission.View

    dialogVisible = false
    create = false

    hooks: ProofFactorHook[] = []
    editing: ProofFactorHook = new ProofFactorHook()

    canCreate = false
    canEdit = false
    async created() {
        this.canCreate = await checkPermissions(ProofFactorPermission.Create)
        this.canEdit = await checkPermissions(ProofFactorPermission.Edit)
        await this.getHooks()
    }
    async getHooks() {
        const res = await createRequest('/proof-factor/hooks', 'get', {limit: 0}).send()
        this.hooks = res.data['hooks']
    }

    async hooksUpdated() {
        await this.getHooks()
    }

    newHook() {
        this.editing = new ProofFactorHook()
        this.editing.url = 'https://'
        this.create = true
        this.dialogVisible = true
    }

    startEdit(proofFactorHook) {
        this.editing = proofFactorHook
        this.create = false
        this.dialogVisible = true
    }
}

